import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import calculatePercentage from "../../../helpers/calculatePercentage";

import Page from "./Page";

export default function Output({ graphics = false }) {
 const { uid, id } = useParams();
 const [currentQuestion, setCurrentQuestion] = useState(null);

 const { status, data } = useGetInteractionQuestions(uid, id);

 const firestore = useFirestore();

 const handleGetCurrentQuestion = async () => {
  await firestore.onSnapshot(
   {
    collection: `users/${uid}/interactions/${id}/questions`,
    doc: `${data.currentQuestion}`,
    storeAs: "interaction",
   },
   (res) => {
    if (!res.exists) {
     setCurrentQuestion(null);
     return null;
    }

    setCurrentQuestion(res.data());
   }
  );

  window.scrollTo(0, 0);
 };

 useEffect(() => {
  handleGetCurrentQuestion();
 }, [data.currentQuestion]);

 useEffect(() => {
  const style = document.createElement("style");

  style.className = "cookieHider";
  style.textContent = ".CookieConsent {display: none!important;}";
  document.head.append(style);
 }, []);

 console.log('data?.settings', data?.settings)
 return (
  <Page graphics={graphics} heading={data.name} backgroundColour={data?.settings?.colourBackground1} textColour={data?.settings?.colourText} vanityUrl={data?.settings?.vanity_url}>
   <section
    style={{
     fontSize: graphics ? "3vh" : "5vh",
     color: data?.settings?.colourText || '#FFFFFF'
    }}
    className=" flex-1"
   >
    {status !== "DONE"
     ? ''
     : !currentQuestion && (
        <span>
         {data?.settings?.waiting_on_host || 'Waiting on the host to ask a question.'}
        </span>
       )}
    {currentQuestion?.type === "poll" && (
     <PollContents poll={currentQuestion} graphics={graphics} showResponses={data?.settings?.responseCount} settings={data?.settings} />
    )}
    {currentQuestion?.type === "emoji" && (
     <EmojiContents poll={currentQuestion} graphics={graphics} showResponses={data?.settings?.responseCount} settings={data?.settings} />
    )}
    {currentQuestion?.type === "openQandA" && (
     <OpenQandAContents content={currentQuestion} graphics={graphics} showResponses={data?.settings?.responseCount} settings={data?.settings} />
    )}
   </section>
  </Page>
 );
}

const PollContents = ({ poll, graphics, showResponses = true, settings }) => {
 return (
  <div className="flex flex-col space-y-4">
   <div
   style={{ backgroundColor: settings?.colourBackground2 || '#212121' }}
    className={`rounded flex justify-between items-center
   ${graphics ? "px-4 py-2" : "px-4 py-6"}
   `}
   >
    <span className="font-semibold">{poll.question}</span>
    {showResponses === false ? '' : <span
     style={{
      fontSize: graphics ? "2vh" : "4vh",
     }}
     className="text-right"
    >
     {poll.totalCount || 0} {settings?.responses || 'response(s)'}
    </span>}
   </div>
   <div className="flex flex-col space-y-2">
    {poll.options.map((option, index) => {
     return (
      <div
       key={index}
       style={{ backgroundColor: settings?.colourBackground2 || '#212121' }}
       className="relative rounded flex w-full items-center"
      >
       <span
        style={{
         width: `${calculatePercentage(
          poll.results?.[index],
          poll.totalCount
         )}`,
         backgroundColor: settings?.colourBackground1 || '#212121'
        }}
        className="absolute rounded h-full w-100 opacity-40 z-0 transition-all duration-300"
       ></span>
       <span
        style={{
         fontSize: graphics ? "2vh" : "3vh",
         padding: "2vh",
        }}
        className=" z-10 font-semibold"
       >
        {index + 1}
       </span>{" "}
       <div
        style={{
         fontSize: graphics ? "3vh" : "",
        }}
        className="flex justify-between w-full"
       >
        <span className="z-10">{option.text}</span>{" "}
        <span className="mr-2 z-10 opacity-80">
         {poll.results?.[index] > 0 &&
          `${calculatePercentage(poll.results?.[index], poll.totalCount)}`}
        </span>
       </div>
      </div>
     );
    })}
   </div>
  </div>
 );
};

const EmojiContents = ({ poll, graphics, showResponses = true, settings }) => {
 return (
  <div className="flex flex-col space-y-4 ">
   <div
    className={`rounded flex justify-between items-center
   ${graphics ? "px-4 py-2" : "px-4 py-6"}
   `}
   style={{ backgroundColor: settings?.colourBackground2 || '#212121' }}
   >
    <span className="font-semibold">{poll.question}</span>
    {showResponses === false ? '' : <span
     style={{
      fontSize: graphics ? "2vh" : "4vh",
     }}
     className="text-right"
    >
     {poll.totalCount || 0} {settings?.responses || 'response(s)'}
    </span>}
   </div>
   <div className="flex space-x-2">
    {poll.options.map((option, index) => {
     return (
      <div
       key={index}
       style={{ backgroundColor: settings?.colourBackground2 || '#212121' }}
       className="relative rounded flex w-full justify-center items-center"
      >
       <div className="flex flex-row w-100 justify-between items-center space-y-2">
        <span
         style={{
          fontSize: graphics ? "4vh" : "6vh",
          padding: "2vh",
         }}
        >
         {option.text}
        </span>
        <span
         style={{
          fontSize: graphics ? "3vh" : "4vh",
          paddingBottom: "2vh",
         }}
         className="text-xs"
        >
         {poll.results?.[index] > 0 && poll.results?.[index]}
        </span>
       </div>
      </div>
     );
    })}
   </div>
  </div>
 );
};

const OpenQandAContents = ({ content, graphics, settings }) => {
 return (
  <div className="flex flex-col space-y-0 ">
   <div
    className={`rounded flex justify-between items-center
   ${graphics ? "px-4 py-2" : "px-4 py-6"}
   `}
   style={{ backgroundColor: settings?.colourBackground2 || '#212121' }}
   >
    {content.question}
   </div>
   <div
    style={{
     gap: "1vh",
    }}
    className="flex flex-col overflow-auto h-auto"
   >
    {/* {!content.results && (
     <span
      style={{
       padding: "2vh",
       fontSize: "3vh",
      }}
      className="italic"
     >
      <span>{settings.waiting_on_responses || 'Waiting on responses.'}.</span>
     </span>
    )} */}
    {content.results?.map((result, index) => {
     if (content.showOnOutputIndex !== index) return null;
     return (
      <span
       style={{
        padding: "2vh",
        marginTop: "2vh",
        backgroundColor: settings?.colourBackground2 || '#212121'
       }}
       className="rounded-lg flex flex-col"
      >
       <span
        className="font-semibold"
        style={{
         fontSize: graphics ? "3vh" : "",
        }}
       >
        {result.text}
       </span>
       <span
        className="opacity-80"
        style={{
         fontSize: graphics ? "2vh" : "3vh",
        }}
       >
        {result.name || "Anonymous"}
       </span>
      </span>
     );
    })}
   </div>
  </div>
 );
};

export const SubmitButton = ({
 disabled = false,
 answer = false,
 onSubmit,
 submitText = "Submit",
}) => {
 return (
  <button
   className={`px-4 py-4 flex justify-between border-2 bg-gray-200 w-full rounded text-left font-extrabold focus:outline-none focus:ring focus:ring-gray-400  transition-all duration-150
   ${
    Boolean(disabled)
     ? "bg-gray-800 border-4 border-gray-900 text-gray-300 cursor-default"
     : "text-gray-900"
   }
   ${answer === -1 && "opacity-30"}
   `}
   disabled={Boolean(disabled) || answer === -1 ? true : false}
   onClick={onSubmit}
  >
   {Boolean(disabled)
    ? "Waiting for host"
    : answer !== -1
    ? submitText
    : "Waiting for your answer"}
   <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
   >
    <path
     strokeLinecap="round"
     strokeLinejoin="round"
     d="m13 9 3 3m0 0-3 3m3-3H8m13 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
    />
   </svg>
  </button>
 );
};

const useGetInteractionQuestions = (uid, id) => {
 const [checking, setChecking] = useState(true);
 const [data, setData] = useState({});
 const [empty, setEmpty] = useState(false);

 const firestore = useFirestore();

 const fetchData = async () => {
  setChecking(true);
  firestore.onSnapshot(
   {
    collection: `users/${uid}/interactions`,
    doc: id,
    storeAs: "interactions",
   },
   (res) => {
    if (!res.exists) {
     setChecking(false);
     return setEmpty(true);
    }

    setData(res.data());
    setChecking(false);
   }
  );
 };

 useEffect(() => {
  fetchData();

  return () => {
   firestore.unsetListener(`users/${uid}/interactions`);
  };
 }, []);

 if (checking) return { status: "CHECKING", data: data };

 if (empty) return { status: "EMPTY", data: data };

 return { status: "DONE", data: data };
};

const AnswerButton = ({ option, answer, onAnswer, index, disabled }) => {
 return (
  <button
   className={`px-4 py-4  rounded text-lg border-4  text-left focus:outline-none focus:ring focus:ring-gray-700  transition-all duration-150
       ${
        parseInt(answer) === index
         ? "bg-gray-900 border-gray-900"
         : "bg-gray-800 border-gray-900"
       }
       ${Boolean(disabled) && "cursor-default"}
      `}
   onClick={() => onAnswer(index)}
   disabled={Boolean(disabled)}
  >
   {option.text}
  </button>
 );
};

export const AnswerInput = ({
 answer,
 onAnswer,
 disabled,
 placeholder = "Type your answer here",
 autoFocus = false,
}) => {
 const [text, setText] = useState("");

 useEffect(() => {
  setText(answer === -1 ? "" : answer);

  return () => {
   console.log("UNloading");
   setText("");
  };
 }, [answer]);
 return (
  <input
   className={`px-4 py-4 w-full rounded text-lg border-4 bg-gray-800 border-gray-900 text-left focus:outline-none focus:ring focus:ring-gray-700  transition-all duration-150
      ${answer ? "bg-gray-900 border-gray-900 " : "bg-gray-800 border-gray-900"}
      
      `}
   value={text}
   placeholder={placeholder}
   onChange={(e) => {
    setText(e.target.value);
    onAnswer(e.target.value);
   }}
   //  onBlur={(e) => onAnswer(e.target.value)}
   disabled={Boolean(disabled)}
   autoFocus={autoFocus}
  />
 );
};
