import store from "../store";

export const updateQuestion = (update) => {
 store.dispatch({
  type: "UPDATE_QUESTION",
  update,
 });
};

export const resetQuestion = (questionType) => {
 store.dispatch({
  type: "RESET_QUESTION",
  questionType,
 });
};

export const editQuestion = (question) => {
 store.dispatch({
  type: "EDIT_QUESTION",
  question,
 });
};

export const resetResults = () => {
 let d = store.dispatch({
  type: "RESET_RESULTS",
 });

 return d;
};
