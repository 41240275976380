const initialData = {};

export default function userReducer(state = initialData, action) {
	switch (action.type) {
		case "ADD_USER":
			return { ...state, ...action.user };

		default:
			return state;
	}
}
