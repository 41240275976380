const questionTypes = {
 poll: {
  type: "poll",
  name: "Poll",
  question: "Which option is better?",
  totalCount: 0,
  results: {},
  options: [
   {
    id: 1,
    text: "Option 1",
   },
   {
    id: 2,
    text: "Option 2",
   },
  ],
 },
 emoji: {
  type: "emoji",
  name: "Emoji Reactions",
  question: "Are enjoying the show?",
  totalCount: 0,
  results: {},
  options: [
   {
    id: 1,
    text: "👏",
   },
   {
    id: 2,
    text: "🎉",
   },
  ],
 },
 openQandA: {
  type: "openQandA",
  name: "Open Q&A",
  question: "How are you feeling today?",
  totalCount: 0,
  allowMultiple: false,
 },
};

module.exports = { questionTypes };
