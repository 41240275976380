import { createStore, combineReducers, compose } from "redux";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"; // <- needed if using firestore
import "firebase/analytics";
import "firebase/storage";
import { firebaseReducer } from "react-redux-firebase";
import {
 reduxFirestore,
 createFirestoreInstance,
 firestoreReducer,
} from "redux-firestore";

import userReducer from "./reducers/userReducer";
import questionReducer from "./reducers/questionReducer";

const firebaseConfig = {
 apiKey: "AIzaSyCARPbzyft-siO0t3sDnsb9kwf6KD5Owj8",
 authDomain: "h2r-graphics-9fb77.firebaseapp.com",
 projectId: "h2r-graphics-9fb77",
 storageBucket: "h2r-graphics-9fb77.appspot.com",
 messagingSenderId: "68061119853",
 appId: "1:68061119853:web:a0a9fc641f5ca55316c664",
 measurementId: "G-BZCRXD11NB",
};

const rrfConfig = {
 userProfile: "users",
 useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

export const fb = firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore(); // <- needed if using firestore
firebase.analytics();
export const storage = firebase.storage();

const createStoreWithFirebase = compose(
 reduxFirestore(firebase, rrfConfig) // firebase instance as first argument, rfConfig as optional second
)(createStore);

const rootReducer = combineReducers({
 user: userReducer,
 question: questionReducer,
 firebase: firebaseReducer,
 firestore: firestoreReducer,
});

// Create store with reducers and initial state
const initialState = {};

const store = createStoreWithFirebase(
 rootReducer,
 initialState,
 window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const rrfProps = {
 firebase,
 config: rrfConfig,
 dispatch: store.dispatch,
 createFirestoreInstance, // <- needed if using firestore
};

export default store;
