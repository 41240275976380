import React from "react";
import {
 BrowserRouter as Router,
 Switch,
 Route,
 Redirect,
 Link,
} from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import CookieConsent from "react-cookie-consent";

import {
 ReactReduxFirebaseProvider,
 isLoaded,
 isEmpty,
} from "react-redux-firebase";

import store, { rrfProps } from "./store";

// import App from "./App";
import SplashScreen from "./components/SplashScreen";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";
import Welcome from "./components/Welcome";
import Sync from "./components/Sync";
import Interactions from "./components/Interactions";
import Interaction from "./components/Interaction";
import Questions from "./components/public/Questions";
import Settings from "./components/Settings";
import Code from "./components/public/Code";
import Output from "./components/public/output/Output";
import QRandInfo from "./components/public/output/QRandInfo";
import Cookies from "./components/legal/Cookies";
import Privacy from "./components/legal/Privacy";
import Terms from "./components/legal/Terms";
import Reset from "./components/auth/Reset";

function AuthIsLoaded({ children }) {
 const auth = useSelector((state) => state.firebase.auth);

 if (!isLoaded(auth)) return <SplashScreen />;
 return children;
}

function PrivateRoute({ children, ...rest }) {
 const auth = useSelector((state) => state.firebase.auth);

 return (
  <Route
   {...rest}
   render={({ location }) =>
    isLoaded(auth) && !isEmpty(auth) ? (
     children
    ) : (
     <Redirect
      to={{
       pathname: `/login`,
       state: { from: location },
      }}
     />
    )
   }
  />
 );
}

export default function AppRouter() {
 return (
  <Provider store={store}>
   <ReactReduxFirebaseProvider {...rrfProps}>
    <Router>
     <AuthIsLoaded>
      <CookieConsent
       location="bottom"
       buttonText="Okay"
       cookieName="h2r-graphics-cloud-essential"
       style={{ background: "#333", fontSize: "13px" }}
       buttonStyle={{
        backgroundColor: "#777",
        color: "white",
        fontSize: "13px !important",
        borderRadius: "10%",
       }}
       expires={150}
      >
       This site uses some cookies to enhance the user experience. Read our{" "}
       <Link className="underline" to="/privacy">
        privacy policy
       </Link>{" "}
       or our{" "}
       <Link className="underline" to="/cookies">
        cookies policy
       </Link>
       .
      </CookieConsent>
      <Switch>
       <Route path="/login">
        <Login />
       </Route>
       <Route path="/signup">
        <Signup />
       </Route>
       <Route path="/reset">
        <Reset />
       </Route>

       <Route path="/cookies">
        <Cookies />
       </Route>
       <Route path="/privacy">
        <Privacy />
       </Route>
       <Route path="/terms">
        <Terms />
       </Route>
       <Route path="/privacy">
        <Privacy />
       </Route>

       <Route path="/code">
        <Code />
       </Route>

       <Route path="/interaction/:uid/:id/qr">
        <QRandInfo />
       </Route>

       <Route path="/interaction/:uid/:id/graphics">
        <Output graphics={true} />
       </Route>

       <Route path="/interaction/:uid/:id/output">
        <Output />
       </Route>

       <Route path="/interaction/:uid/:id/questions">
        <Questions />
       </Route>

       <PrivateRoute path="/welcome">
        <Welcome />
       </PrivateRoute>

       <PrivateRoute path="/interactions/:id">
        <Interaction />
       </PrivateRoute>

       <PrivateRoute path="/interactions">
        <Interactions />
       </PrivateRoute>

       <PrivateRoute path="/sync">
        <Sync />
       </PrivateRoute>

       <PrivateRoute path="/">
        <Settings />
       </PrivateRoute>
      </Switch>
     </AuthIsLoaded>
    </Router>
   </ReactReduxFirebaseProvider>
  </Provider>
 );
}
