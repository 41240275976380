import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";

import H2RButton from "../H2RComponents/H2RButton";
import H2RInput from "../H2RComponents/H2RInput";
import Logo from "../Logo";
// import GoogleButton from 'react-google-button' // optional

const valid = {
 name: true,
 nameLast: true,
 email: true,
 password: true,
};

function Signup() {
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [marketingEmails, setMarketingEmails] = useState(false);
 const [validation, setValidation] = useState({ ...valid });
 const [errorMessage, setErrorMessage] = useState("");
 const [signingUp, setSigningUp] = useState(false);

 const firebase = useFirebase();
 const auth = useSelector((state) => state.firebase.auth);

 //  const handleSignup = async (e) => {
 //   e.preventDefault();

 //   if (!name || !email || !password)
 //    return setValidation({
 //     name: name === "" ? false : true,
 //     email: email === "" ? false : true,
 //     password: password === "" ? false : true,
 //    });

 //   setSigningUp(true);

 //   await firebase
 //    .createUser({ email, password }, { displayName: name })
 //    .then((res) => {
 //     console.log(res);
 //    })
 //    .catch((err) => {
 //     setErrorMessage(err.message);
 //     setSigningUp(false);
 //    });
 //  };

 const handleSignup = async (e) => {
  e.preventDefault();

  if (!name || !email || !password)
   return setValidation({
    name: name === "" ? false : true,
    email: email === "" ? false : true,
    password: password === "" ? false : true,
   });

  setSigningUp(true);

  //   try {
  await firebase
   .createUser({ email, password }, { displayName: name, marketingEmails })
   .then((res) => {
    console.log(res);
   })
   .catch((err) => {
    setErrorMessage(err.message);
   });

  //   const userDoc = await firestore
  //    .doc(`users/${auth.uid}`)
  //    .onSnapshot((snapshot) => {
  //     console.log(snapshot);
  //    });

  //   await firestore.collection("users").doc(user.uid).update({ uid: user });

  // CREATE USER DOC

  // REDIRECT TO /
  //   } catch (error) {
  //    setErrorMessage(error.message);
  //   }
 };

 return (
  <div className="flex flex-col justify-center bg-gray-800 h-screen w-screen">
   {/* // <GoogleButton/> button can be used instead */}
   {!isLoaded(auth) ? (
    <span>Loading...</span>
   ) : isLoaded(auth) && isEmpty(auth) ? (
    <div className="px-12 py-10 flex flex-col w-11/12 md:w-1/2 max-w-md bg-gray-900 mx-auto rounded-lg">
     <form
      className="flex flex-col mb-5"
      onSubmit={(e) => {
       e.preventDefault();
       handleSignup(e);
      }}
     >
      <Logo />
      <span className="mt-8 mb-10 h-0.5  bg-gray-800"></span>

      <div className="flex flex-col space-y-4 mb-8">
       <H2RInput
        name="name"
        label="Name"
        type="text"
        value={name}
        onChange={(change) => setName(change)}
        placeholder="My name"
        required={true}
        autoComplete="name"
        valid={validation.name}
       />

       <H2RInput
        name="email"
        label="Email"
        type="email"
        css="mt-6"
        value={email}
        onChange={(change) => setEmail(change)}
        placeholder="me@address.com"
        required={true}
        autoComplete="email"
        valid={validation.email}
       />
       <H2RInput
        name="password"
        label="Password"
        type="password"
        css="mt-6"
        value={password}
        onChange={(change) => setPassword(change)}
        placeholder="••••••••••"
        required={true}
        autoComplete="new-password"
        valid={validation.password}
       />
       {/* <H2RCheckbox
        size="small"
        label="Product update emails"
        checked={marketingEmails}
        onChange={() => setMarketingEmails(!marketingEmails)}
        checkboxLabel={
         marketingEmails
          ? "On - Send me product update emails"
          : "Off - Don't send me product update emails"
        }
       /> */}
      </div>

      <H2RButton
       text={!signingUp ? "Sign up" : "Signing up..."}
       onClick={(e) => handleSignup(e)}
       disabled={signingUp}
      />
     </form>

     {errorMessage !== "" ? (
      <span className="italic mt-4 mx-auto text-red-600">{errorMessage}</span>
     ) : null}
     {/* <span className="mt-2 mb-6 h-1 border bg-gray-100"></span>
     <Button
      text="Login with Google"
      colour="white"
      onClick={loginWithGoogle}
     /> */}
    </div>
   ) : (
    <Redirect to="/welcome"></Redirect>
   )}
  </div>
 );
}

export default Signup;
