import { questionTypes } from "../types/questionTypes";

const initialData = {
 ...questionTypes["poll"],
};

export default function questionReducer(state = initialData, action) {
 switch (action.type) {
  case "UPDATE_QUESTION":
   return { ...state, ...action.update };

  case "RESET_RESULTS":
   return { ...state, results: null, totalCount: 0 };

  case "RESET_QUESTION":
   return { ...questionTypes[action.questionType] };

  case "EDIT_QUESTION":
   return { ...action.question };

  default:
   return state;
 }
}
