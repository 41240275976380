import React, { useState, useEffect } from "react";
import LoadingBar from "react-top-loading-bar";
import Logo from "./Logo";

const phrases = [
 "Drawing SVGs",
 "Loading animations",
 "Building theme builder",
 "Fine-tuning chroma green colour",
];

const getRndInteger = (min, max) => {
 return Math.floor(Math.random() * (max - min)) + min;
};

function SplashScreen() {
 const [currentPhrase, setCurrentPhase] = useState(
  phrases[getRndInteger(0, phrases.length - 1)]
 );
 const [progress, setProgress] = useState(90);

 useEffect(() => {
  const interval = setInterval(() => {
   setCurrentPhase(phrases[getRndInteger(0, phrases.length - 1)]);
  }, 5000);

  return () => {
   clearInterval(interval);
  };
 }, []);

 // Exclude any /output paths from the SplashScreen
 return window.location.pathname.includes("/output") ? (
  <LoadingBar
   color={"rgba(202, 138, 4)"}
   progress={progress}
   onLoaderFinished={() => setProgress(0)}
  />
 ) : (
  <div className="flex flex-col justify-center bg-gray-800 h-screen w-screen">
   <div className="px-12 py-10 flex flex-col max-w-sm bg-gray-900 mx-auto rounded-lg">
    <div className="flex flex-col mb-5">
     <Logo />
     <span className="mt-8 mb-10 h-0.5  bg-gray-800"></span>
     <span className="text-center text-gray-500 animate-bounce">
      {currentPhrase}
     </span>
    </div>
   </div>
  </div>
 );
}

export default SplashScreen;
