import { useRef, useState, useMemo, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Page from "./Page";
import { AnswerInput, SubmitButton } from "./Questions";

function useQuery() {
 const { search } = useLocation();

 return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Code() {
 const query = useQuery();

 const [code, setCode] = useState("");
//  const [code2, setCode2] = useState("");
 const [valid, setValid] = useState("");
 const [name, setName] = useState("Anonymous");

 const [entering, setEntering] = useState(false);

 const input1 = useRef();
//  const input2 = useRef();

 const firestore = useFirestore();
 const history = useHistory();

 const handleOnSubmit = async () => {
  setValid("");
  setEntering(true);
  if (!code) {
   setEntering(false);
   return setValid("This code is not valid.");
  }
  const res = await firestore
   .doc(`codes/${code}`)
   .get()
   .then((doc) => {
    return doc;
   });

  if (!res.exists) {
   setEntering(false);
   return setValid("This code is not valid.");
  }

  let { uid, expires, interactionProjectId } = res.data();

  // This is to be backwards compatible with the older 6 digit code
  let codeOrProject = interactionProjectId ? interactionProjectId : code

  if (!uid) {
   setEntering(false);
   return setValid("Event not found.");
  }

  if (expires.seconds * 1000 - new Date().getTime() < 0) {
   setEntering(false);
   return setValid("This code has expired.");
  }

  localStorage.setItem("name", name);
  let sessionRef = await firestore
   .collection(`users/${uid}/interactions/${codeOrProject}/sessions`)
   .add({
    entered: new Date(),
   });

  return history.push(
   `interaction/${uid}/${codeOrProject}/questions?session=${sessionRef.id}`
  );
 };

 useEffect(() => {
  let urlQuery = query.get("c");
  if (urlQuery) {
   setCode(urlQuery);
  //  setCode2(`${urlQuery[3]}${urlQuery[4]}${urlQuery[5]}`);
  }
 }, []);

 return (
  <Page heading="Code">
   <section className="flex-1 space-y-4">
    <span className="pt-4 block">
     {code ? "Event code" : "Enter the code provided by your host."}
    </span>
    <div className="flex items-center">
     <input
      ref={input1}
      disabled={entering}
      // maxLength="3"
      className={`w-full px-4 py-4 rounded text-lg border-4 bg-gray-800 border-gray-900 text-left focus:outline-none focus:ring focus:ring-gray-700  transition-all duration-150
     
     `}
      value={code}
      placeholder="12345678"
      pattern="\d*"
      onChange={(e) => {
       setCode(e.target.value);
      //  if (e.target.value.length === 3) {
      //   input2.current.focus();
      //   input2.current.select();
      //  }
      }}
     />
     {/* <span className="w-20 px-2 text-center">-</span>
     <input
      ref={input2}
      disabled={entering}
      maxLength="3"
      className={`w-full px-4 py-4 rounded text-lg border-4 bg-gray-800 border-gray-900 text-left focus:outline-none focus:ring focus:ring-gray-700  transition-all duration-150
     
     `}
      value={code2}
      placeholder="456"
      pattern="\d*"
      onChange={(e) => setCode2(e.target.value)}
     /> */}
    </div>
    <span className="pt- block text-red-500">{valid}</span>

    <span className="pt-4 block">What's your name? (Optional)</span>
    <AnswerInput
     placeholder={"My name"}
     answer={""}
     onAnswer={(text) => setName(text)}
     autoFocus={true}
    />
   </section>

   <span className="pt-4 block text-red-500"></span>
   <SubmitButton
    answer={code ? 1 : -1}
    onSubmit={handleOnSubmit}
    submitText="Enter"
    disabled={entering}
   />
  </Page>
 );
}
