import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

export default function Slideover({
 open = false,
 title = "",
 onClose,
 children,
}) {
 return (
  <Transition.Root show={open} as={Fragment}>
   <Dialog
    as="div"
    className="fixed inset-0 overflow-hidden z-50"
    onClose={onClose}
   >
    <div className="absolute inset-0 overflow-hidden">
     <Dialog.Overlay
      className="absolute inset-0 transition-all"
      style={{
       backgroundColor: "rgba(1,1,1,0.3)",
      }}
     />

     <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
      <Transition.Child
       as={Fragment}
       enter="transform transition ease-in-out duration-250 sm:duration-500"
       enterFrom="translate-x-full"
       enterTo="translate-x-0"
       leave="transform transition ease-in-out duration-250 sm:duration-500"
       leaveFrom="translate-x-0"
       leaveTo="translate-x-full"
      >
       <div className="pointer-events-auto w-screen max-w-md bg-gray-800  border-l-2 border-gray-900">
        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
         <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
           <Dialog.Title className="text-lg font-medium text-gray-200">
            {" "}
            {title}{" "}
           </Dialog.Title>
           <div className="ml-3 flex h-7 items-center">
            <button
             type="button"
             className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-main-500"
             onClick={() => onClose(false)}
            >
             <span className="sr-only">Close panel</span>
             <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
           </div>
          </div>
         </div>
         <div className="relative mt-6 flex-1 px-4 sm:px-6">
          {/* Replace with your content */}
          <div className="absolute inset-0 px-4 sm:px-6">
           <div className="h-full flex flex-col space-y-4" aria-hidden="true">
            {children}
           </div>
          </div>
          {/* /End replace */}
         </div>
        </div>
       </div>
      </Transition.Child>
     </div>
    </div>
   </Dialog>
  </Transition.Root>
 );
}
