import { useRef, useState } from "react";
import H2RButton from "../H2RComponents/H2RButton";
import H2RInput from "../H2RComponents/H2RInput";

export default function InputWithCopy({
 label,
 value,
 open = false,
 type = "text",
}) {
 const [icon, setIcon] = useState("clipboard");
 const inputRef = useRef();

 const handleCopy = () => {
  setIcon("tick");

  navigator.clipboard.writeText(inputRef.current.value);

  setTimeout(() => {
   setIcon("clipboard");
  }, [3000]);
 };

 const handleOpen = () => {
  window.open(`${value}`, "_blank");
 };
 return (
  <div className="flex space-x-1 items-end">
   <H2RInput
    type={type}
    key={value}
    label={label}
    value={value}
    disabled={true}
    onChange={() => null}
    size="small"
    colour="dark"
   />
   <input className="w-0" ref={inputRef} value={value} />
   {open && (
    <H2RButton
     text=""
     icon={`external`}
     colour="dark"
     size="small"
     onClick={handleOpen}
    />
   )}
   <H2RButton
    text=""
    icon={icon}
    colour="dark"
    size="small"
    onClick={handleCopy}
   />
  </div>
 );
}
