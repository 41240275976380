import { useState } from "react";
import PropTypes from "prop-types";

const H2RCheckbox = ({
	type = "checkbox",
	placeholder = "",
	checked = false,
	size = "large",
	onChange,
	label = "",
	checkboxLabel = "",
	colour = "dark",
	disabled = false,
	randomId,
}) => {
	const [val, setVal] = useState(checked);

	return (
		<div className="w-max relative">
			<span
				className={`text-gray-500 tracking-wide font-semibold
				${size === "small" ? "text-xs" : "text-sm"}
				`}
			>
				{label}
			</span>
			<label
				htmlFor={`${randomId}_${checkboxLabel}`}
				className={`flex justify-center items-center rounded cursor-pointer
             ${size === "small" ? "text-sm px-2 " : "px-3 py-1"}
			${colour === "dark" && "bg-gray-700"}
			${colour === "light" && "bg-gray-200   text-gray-800"}
            `}
			>
				<input
					className="w-4 h-4 ml-1 mr-2 my-2 "
					id={`${randomId}_${checkboxLabel}`}
					type={type}
					placeholder={placeholder}
					checked={val}
					onChange={(e) => {
						if (disabled) return null;
						onChange(e.target.checked);
						setVal(e.target.checked);
					}}
					disabled={disabled}
				/>
				<span
					className={`w-100 h-full leading-none select-none
           
            
            `}
					htmlFor={`${label}_${checkboxLabel}`}
				>
					{checkboxLabel}{" "}
				</span>
			</label>
		</div>
	);
};

H2RCheckbox.propTypes = {
	type: PropTypes.string,
	css: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	randomId: PropTypes.string.isRequired,
};

export default H2RCheckbox;
