import { useState } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";

const H2RInput = ({
	type = "text",
	placeholder = "",
	value = "",
	size = "large",
	onChange,
	onBlur = () => {},
	label = "",
	labelValue = "",
	labelValueEditable = false,
	colour = "dark",
	colourPicker = false,
	min = 0,
	max = 1,
	resetValue = null,
	disabled = false,
	step = 1,
}) => {
	const [val, setVal] = useState(value);
	const [pickerOpen, setPickerOpen] = useState(false);
	const [tempColour, setTempColour] = useState(value);

	return (
		<div className="w-full relative">
			<span
				className={`text-gray-500 tracking-wide font-semibold
				${size === "small" ? "text-xs" : "text-sm"}
				`}
			>
				{label} {type === "range" && !labelValueEditable && `- ${labelValue}`}
				{type === "range" && labelValueEditable && (
					<>
						-
						<input
							className="px-1 w-max bg-gray-800 font-bold rounded hover:bg-gray-700 focus:outline-none focus:ring ring-main-500"
							value={labelValue}
							min={min}
							max={max}
							step={step}
							onChange={(e) => {
								if (disabled) return null;
								onChange(e.target.value);
								setVal(e.target.value);
							}}
							type="number"
						/>
					</>
				)}
			</span>
			{pickerOpen && (
				<span
					className={`flex flex-col absolute z-20 left-0 text-gray-900
								${label !== "" ? "mt-10" : "mt-8"}
				`}
				>
					<div>
						<SketchPicker
							disableAlpha={true}
							color={tempColour}
							onChange={(change) => {
								setTempColour(change.hex);
							}}
							onChangeComplete={(change) => {
								setTempColour(change.hex);
								setVal(change.hex);
								onChange(change.hex);
							}}
						/>
					</div>
					<button
						onClick={() => {
							setPickerOpen(false);
						}}
						className="bg-gray-100 w-100 mx-auto px-6 rounded mt-1 focus:outline-none focus:ring ring-main-500"
					>
						Close
					</button>
				</span>
			)}
			<div className="flex">
				{colourPicker && (
					<span
						onClick={() => setPickerOpen(!pickerOpen)}
						className={`w-20 mr-2 rounded border-2 cursor-pointer transition-colors duration-300 ease-in-out
								${pickerOpen ? "border-gray-300" : "border-gray-600"}
						`}
						style={{ backgroundColor: tempColour }}
					>
						{" "}
					</span>
				)}
				<input
					className={`w-full leading-none rounded transition duration-300 ease-in-out focus:outline-none focus:ring focus:ring-main-500
            ${size === "small" ? "text-sm px-2 py-1" : "px-3 py-1"}
			${colour === "dark" && "bg-gray-700"}
			${colour === "light" && "bg-gray-200   text-gray-800"}

            
            `}
					type={type}
					placeholder={placeholder}
					value={val}
					onChange={(e) => {
						if (disabled) return null;
						if (colourPicker) setTempColour(e.target.value);
						onChange(e.target.value);
						setVal(e.target.value);
					}}
					onBlur={(e) => {
						if (disabled) return null;
						if (colourPicker) setTempColour(e.target.value);
						onBlur(e.target.value);
						setVal(e.target.value);
					}}
					min={min}
					max={max}
					step={step}
					disabled={disabled}
				/>
				{resetValue && (
					<button
						onClick={() => {
							onChange(resetValue);
							setVal(resetValue);
						}}
						className="ml-1 px-2 py-1 focus:outline-none focus:ring focus:ring-main-500 rounded"
					>
						<svg
							className="w-4 h-4"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M3.055 6h-.388V2.667L3.055 6zm0 0a5.334 5.334 0 0110.237 1.333M3.055 6H6m6.946 4h.387v3.333L12.946 10zm0 0A5.336 5.336 0 012.708 8.667M12.946 10H10"
								stroke="currentColor"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				)}
			</div>
		</div>
	);
};

H2RInput.propTypes = {
	type: PropTypes.string,
	css: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

export default H2RInput;
