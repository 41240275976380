import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import moment from "moment";
import randomatic from "randomatic";

import Page from "./Entry";
import H2RHeading from "./H2RComponents/H2RHeading";
import Collapsable from "./snippets/Collapsable";
import InputWithCopy from "./snippets/InputWithCopy";

export default function Sync() {
 const [showCredentials, setShowCredentials] = useState(false);
 const { status, data } = useGetSyncProjects();

 const uid = useSelector((state) => state.firebase.auth.uid);

 return (
  <Page title="Sync" subtitle="Sync your H2R Graphics projects across devices.">
   <Collapsable
    heading="Credentials"
    opened={showCredentials}
    toggle={() => {
     setShowCredentials(!showCredentials);
    }}
   >
    <div className="flex flex-col space-y-4">
     <InputWithCopy value={uid} label={`User ID`} />
     {/* <div className="flex flex-col ">
      <span className="mt-1 uppercase text-xs text-gray-600 font-semibold">
       User ID
      </span>
      <span className="px-1 mt-1 text-sm bg-gray-700 rounded text-gray-400 select-text overflow-hidden">
       {uid}
      </span>
     </div> */}
     <UserToken type="Read/Write" />
     <UserToken type="Read" />
    </div>
   </Collapsable>

   <H2RHeading type="h4">Projects</H2RHeading>
   <div className="mt-4 w-full flex flex-col space-y-4">
    {status === "CHECKING" && <SyncProject checking={true} />}
    {status === "EMPTY" && (
     <span className="italic text-gray-600">No projects found.</span>
    )}
    {status === "DONE" && (
     <>
      {Object.entries(data).map(([d, di]) => {
       return <SyncProject key={d} id={d} syncProject={di} />;
      })}
     </>
    )}
   </div>
  </Page>
 );
}

const SyncProject = ({ checking = false, id, syncProject }) => {
 if (checking) {
  return <div className="h-16 bg-gray-900 rounded animate-pulse"></div>;
 }
 return (
  <div className="px-6 py-4 w-full flex justify-between items-center bg-gray-900 rounded">
   <div className="flex flex-col">
    <span className="font-bold">{syncProject.name}</span>
    <span className="text-xs text-gray-400">Project ID: {id}</span>
   </div>

   <span className="text-sm italic text-gray-600">
    Latest: {moment(syncProject.lastSynced.toDate()).format("Do MMM, HH:mm:ss")}
   </span>
  </div>
 );
};

const useGetSyncProjects = () => {
 let [checking, setChecking] = useState(true);
 let [data, setData] = useState({});
 const [empty, setEmpty] = useState(false);

 const uid = useSelector((state) => state.firebase.auth.uid);

 const firestore = useFirestore();

 const fetchData = async () => {
  setChecking(true);
  firestore.onSnapshot(
   {
    collection: `users/${uid}/sync`,
    orderBy: ["lastSynced", "desc"],
    storeAs: "sync",
   },
   (res) => {
    if (res.docs.length === 0) {
     setChecking(false);
     return setEmpty(true);
    }

    let docs = {};

    res.docs.map((d) => {
     return (docs = {
      ...docs,
      [d.id]: {
       ...d.data(),
      },
     });
    });

    setData(docs);
    setChecking(false);
   }
  );
 };

 useEffect(() => {
  fetchData();

  return () => {
   firestore.unsetListener(`users/${uid}/sync`);
  };
 }, []);

 if (checking) return { status: "CHECKING", data: data };

 if (empty) return { status: "EMPTY", data: data };

 return { status: "DONE", data: data };
};

const UserToken = ({ type = "Read" }) => {
 const uid = useSelector((state) => state.firebase.auth.uid);
 const token =
  useSelector((state) => state.firebase.profile.syncToken) ||
  "No token generated";
 const tokenRead =
  useSelector((state) => state.firebase.profile.syncTokenReadOnly) ||
  "No token generated";

 const firestore = useFirestore();

 const handleGenerateNewToken = async (t) => {
  if (t === "Read/Write") {
   await firestore.doc(`users/${uid}`).update({
    syncToken: randomatic("Aa0", 30),
   });
  } else if (t === "Read") {
   await firestore.doc(`users/${uid}`).update({
    syncTokenReadOnly: randomatic("Aa0", 30),
   });
  }
 };

 return (
  <div className="flex flex-col  ">
   <InputWithCopy
    label={`Token - ${type}`}
    value={type === "Read/Write" ? token : tokenRead}
   />
   {/* <span className="mt-1 uppercase text-xs text-gray-600 font-semibold">
    Token - {type}
   </span>
   <span className="px-1 mt-1 text-sm bg-gray-700 rounded text-gray-400 select-text overflow-hidden">
    {type === "Read/Write" && token}
    {type === "Read" && tokenRead}
   </span> */}
   <button
    className="mt-1 w-min whitespace-nowrap text-gray-500 text-xs font-semibold uppercase ml-auto focus:outline-none focus:ring rounded"
    onClick={() => handleGenerateNewToken(type)}
   >
    Generate new token
   </button>
  </div>
 );
};
