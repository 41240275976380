import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import firebase from "firebase/app";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

import AppRouter from "./AppRouter";
import store from "./store";

const rrfConfig = {
 userProfile: "users",
 useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};
const rrfProps = {
 firebase,
 config: rrfConfig,
 dispatch: store.dispatch,
 createFirestoreInstance, // <- needed if using firestore
};

ReactDOM.render(
 <React.StrictMode>
  <Provider store={store}>
   <ReactReduxFirebaseProvider {...rrfProps}>
    <AppRouter />
   </ReactReduxFirebaseProvider>
  </Provider>
 </React.StrictMode>,
 document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
