import React from "react";
import { Link } from "react-router-dom";

import Logo from "./Logo";
import H2RButton from "./H2RComponents/H2RButton";

export default function Welcome() {
	return (
		<div className="flex flex-col justify-center bg-gray-800 h-screen w-screen">
			<div className="px-12 py-10 flex flex-col bg-gray-900 max-w-sm mx-auto rounded-lg">
				<div className="flex flex-col mb-5">
					<Logo />

					<span className="mt-8 mb-10 h-0.5 bg-gray-700"></span>

					<p className="leading-snug font-semibold">Account setup complete.</p>
					<p className="mt-4 leading-snug text-gray-600">
						If you need any help along the way, head over to{" "}
						{<Link to="/help">/help</Link>}, or send an email to
						support@heretorecord.com.
					</p>

					<Link to="/" className="mt-6">
						<H2RButton text="Let's get started..." size="small" colour="dark" />
					</Link>
				</div>
			</div>
		</div>
	);
}
