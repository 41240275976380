import { useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

import idToCode from "../../../helpers/idToCode";

export default function Page({ heading = "", graphics = false, textColour, backgroundColour, children, vanityUrl }) {
 const { id } = useParams();
 return (
  <div
   style={{
    padding: graphics ? "3vh" : "",
    //  margin: graphics ? "2vh" : "0",
    backgroundColor: graphics ? 'transparent' : (backgroundColour || '#313131')
   }}
   className={`h-screen
  ${graphics ? "flex flex-col justify-end" : ""}
  `}
  >
   {graphics && (
    <div className="flex justify-end">
     <span
      style={{ zIndex: "-1", fontSize: "2vh", borderRadius: "0.5vh 0.5vh 0 0", backgroundColor: backgroundColour || 'rgb(49 49 49)', color: textColour || 'white' }}
      className="mr-2 pt-2 px-3 pb-2 w-max self-end font-semibold"
     >
      {vanityUrl || `h2r.li/${id}`}
     </span>
     <span
      style={{ zIndex: "-1", fontSize: "2vh", borderRadius: "0.5vh 0.5vh 0 0", backgroundColor: backgroundColour || 'rgb(49 49 49)', color: textColour || 'white' }}
      className="mr-2 pt-2 px-2 pb-2 w-max self-end font-semibold"
     >
      <QRCodeSVG
       value={vanityUrl || `https://h2r.li/${id}`}
       style={{
        height: `12vh`,
        width: `12vh`,
        aspectRatio: `1/1`,
        padding: ".5vw",
        backgroundColor: "white",
       }}
      />
      {/* cloud.h2r.graphics/code • {idToCode(id)}{" "} */}
     </span>
    </div>
   )}
   <main
    // style={{ height: `calc(100vh - 100px)` }}
    className={`relative bg-gray-800

    ${graphics ? "w-full" : "w-screen"}
    

    `}
    style={{
     padding: graphics ? "3vh" : "6vh",
     //  margin: graphics ? "2vh" : "0",
     borderRadius: "1vh",
     backgroundColor: backgroundColour || 'rgb(49 49 49)'
    }}
   >
    <div
     className={`flex flex-col h-full
    ${graphics ? "space-y-0" : "space-y-3"}
    `}
    >
     <h1
      style={{
       fontSize: `10vh`,
      }}
      className="font-semibold"
     >
      {graphics ? "" : heading}
     </h1>
     {/* <Textfit mode={heading.length < 30 ? "multi" : "single"}>
      {heading}
     </Textfit> */}
     {children}
     {/* <section className="py-4 text-xs text-gray-500">
      Powered by H2R Graphics Cloud
     </section> */}
    </div>
   </main>
  </div>
 );
}
