import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import moment from "moment";
import _indexOf from "lodash/indexOf"

import Page from "./Entry";
import H2RHeading from "./H2RComponents/H2RHeading";
import H2RButton from "./H2RComponents/H2RButton";
import H2RCheckbox from "./H2RComponents/H2RCheckbox";
import { useParams } from "react-router-dom";
import H2RInput from "./H2RComponents/H2RInput";
import Slideover from "./Slideover";
import { questionTypes } from "../types/questionTypes";

import * as questionActions from "../actions/questionActions";
import Collapsable from "./snippets/Collapsable";
import InputWithCopy from "./snippets/InputWithCopy";
import idToCode from "../helpers/idToCode";
import calculatePercentage from "../helpers/calculatePercentage";
import { firestore } from "../store";

export default function Interaction() {
 const { id } = useParams();
 const [expired, setExpired] = useState(false);
 const [showShareLinks, setShowShareLinks] = useState(false);
 const [showSettings, setShowSettings] = useState(false);

 const { status, data } = useGetInteractionProjects(id);
 const uid = useSelector((state) => state.firebase.auth.uid);

 useEffect(() => {
  let d = new Date();
  if (data) {
   if (data?.interaction?.expires?.seconds * 1000 - d.getTime() < 0) {
    setExpired(true);
   } else {
    setExpired(false);
   }
  }
 }, [data]);

 return (
  <Page
   title={data.interaction?.name}
   subtitle={``}
   nav={false}
   breadcrumb={"/interactions"}
  >
   {expired && (
    <div className="p-2 mb-2 bg-gray-900 rounded">
     ⛔️ This event has expired!
    </div>
   )}
   <div className="flex space-x-4">
    <div className="w-2/3 flex flex-col-reverse justify-end h-full">
     <div
      //  style={{
      //   aspectRatio: `16 / 9`,
      //  }}
      className="w-100"
     >
      <div className="w-full flex flex-col space-y-4">
       {status === "CHECKING" && <InteractionProject checking={true} />}
       {status === "EMPTY" && (
        <span className="italic text-gray-600">No projects found.</span>
       )}
       {status === "DONE" && (
        <InteractionProject id={id} interactionProject={data} />
       )}
      </div>
     </div>
     <div className="pb-6 flex flex-col space-y-3 mx-auto w-full h-full">
      <span className="text-sm text-gray-500">
       <span className="pr-4">
        <span className="text-xs font-semibold uppercase">Code</span>:{" "}
        {`${idToCode(id)}`}
       </span>
       <span className="text-xs font-semibold uppercase">Expires</span>:{" "}
        {moment(data.interaction?.expires?.seconds * 1000).fromNow()}
      </span>

      <Collapsable
       heading="Share code & links"
       opened={showShareLinks}
       toggle={() => {
        setShowShareLinks(!showShareLinks);
       }}
      >
       <div className="flex flex-col space-y-2">
        <H2RHeading type="h6">Audience</H2RHeading>

        <InputWithCopy value={`${idToCode(id)}`} label="Interaction code" />

        <InputWithCopy
         value={`https://h2r.li/${id}`}
         open={true}
         label="Audience join link"
        />

        <H2RHeading type="h6">Output</H2RHeading>

        <InputWithCopy
         value={`${window.location.origin}/interaction/${uid}/${id}/qr`}
         open={true}
         label="QR code and info"
        />

        <InputWithCopy
         value={`${window.location.origin}/interaction/${uid}/${id}/output`}
         open={true}
         label="Prompter output"
        />

        <InputWithCopy
         value={`${window.location.origin}/interaction/${uid}/${id}/graphics`}
         open={true}
         label="Graphics output"
        />
       </div>
      </Collapsable>

      <Collapsable
       heading="Settings"
       opened={showSettings}
       toggle={() => {
        setShowSettings(!showSettings);
       }}
      >
       <div className="flex flex-col space-y-2">
        <H2RHeading type="h6">Outputs</H2RHeading>

        <H2RCheckbox
          label="Show or hide the response count"
          randomId="showHideRes"
          checked={data?.interaction?.settings?.responseCount !== false}
          checkboxLabel={data?.interaction?.settings?.responseCount === false ? "No, hide the response count" : "Yes, show the amount of responses"}
          onChange={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.responseCount': change
          })}
        />

        <div className="grid grid-cols-3 gap-4">
          <H2RInput
            label="Text colour"
            value={data?.interaction?.settings?.colourText || '#ffffff'}
            colourPicker={true}
            onChange={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
              'settings.colourText': change
            })}
          />
          <H2RInput
            label="Background colour #1"
            value={data?.interaction?.settings?.colourBackground1 || '#313131'}
            colourPicker={true}
            onChange={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
              'settings.colourBackground1': change
            })}
          />
          <H2RInput
            label="Background colour #2"
            value={data?.interaction?.settings?.colourBackground2 || '#212121'}
            colourPicker={true}
            onChange={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
              'settings.colourBackground2': change
            })}
          />
        </div>

        <H2RHeading type="h6">Links</H2RHeading>
        <H2RInput
          label="Vanity URL for outputs (Note: You'll need to implement the functionality on your own end)"
          value={data?.interaction?.settings?.vanity_url || ''}
          placeholder={`h2r.li/${id}`}
          onChange={()=>{}}
          onBlur={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.vanity_url': change
          })}
        />

        <H2RHeading type="h6">Phrases</H2RHeading>
        <H2RInput
          label="'Waiting on the host to ask a question.'"
          value={data?.interaction?.settings?.waiting_on_host || 'Waiting on the host to ask a question.'}
          placeholder="Waiting on the host to ask a question."
          onChange={()=>{}}
          onBlur={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.waiting_on_host': change
          })}
        />
        <H2RInput
          label="'Scan QR code to join'"
          value={data?.interaction?.settings?.scan_to_join || 'Scan QR code to join'}
          placeholder="Scan QR code to join"
          onChange={()=>{}}
          onBlur={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.scan_to_join': change
          })}
        />
        <H2RInput
          label="'Visit the link to join'"
          value={data?.interaction?.settings?.visit_link || 'Visit the link to join'}
          placeholder="Visit the link to join"
          onChange={()=>{}}
          onBlur={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.visit_link': change
          })}
        />
        <H2RInput
          label="'Waiting for host'"
          value={data?.interaction?.settings?.waiting_for_host || 'Waiting for host'}
          placeholder="Waiting for host"
          onChange={()=>{}}
          onBlur={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.waiting_for_host': change
          })}
        />
        <H2RInput
          label="'Waiting for your answer'"
          value={data?.interaction?.settings?.waiting_for_your_answer || 'Waiting for your answer'}
          placeholder="Waiting for your answer"
          onChange={()=>{}}
          onBlur={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.waiting_for_your_answer': change
          })}
        />
        <H2RInput
          label="'Submit'"
          value={data?.interaction?.settings?.submit || 'Submit'}
          placeholder="Submit"
          onChange={()=>{}}
          onBlur={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.submit': change
          })}
        />
        <H2RInput
          label="'Submitted!'"
          value={data?.interaction?.settings?.submitted || 'Submitted!'}
          placeholder="Submitted!"
          onChange={()=>{}}
          onBlur={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.submitted': change
          })}
        />
        <H2RInput
          label="'response(s)'"
          value={data?.interaction?.settings?.responses || 'response(s)'}
          placeholder="response(s)"
          onChange={()=>{}}
          onBlur={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.responses': change
          })}
        />
        {/* <H2RInput
          label="'Waiting on responses.'"
          value={data?.interaction?.settings?.waiting_on_responses || 'Waiting on responses.'}
          placeholder="Waiting on responses."
          onChange={()=>{}}
          onBlur={async (change) =>  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
            'settings.waiting_on_responses': change
          })}
        /> */}

       </div>
      </Collapsable>
     </div>
    </div>

    <div className="w-1/3">
     <div className="space-y-2 sticky top-2">
      <H2RHeading type="h4">Prompter Output</H2RHeading>
      <div
       style={{ aspectRatio: "16 / 9" }}
       className="rounded border border-gray-900"
      >
       <iframe
        title="Prompter output"
        width="100%"
        height="100%"
        className="bg-gray-700 rounded"
        src={`${window.location.origin}/interaction/${uid}/${id}/output`}
       ></iframe>
      </div>
      <H2RHeading type="h4">Graphics Output</H2RHeading>
      <div
       style={{ aspectRatio: "16 / 9" }}
       className="rounded border border-gray-900"
      >
       <iframe
        title="Prompter output"
        width="100%"
        height="100%"
        className="bg-gray-700 rounded"
        src={`${window.location.origin}/interaction/${uid}/${id}/graphics`}
       ></iframe>
      </div>
     </div>
    </div>
   </div>
  </Page>
 );
}

const InteractionProject = ({
 checking = false,
 id,
 interactionProject = {},
}) => {
 const { interaction = {}, questions = {}, sessions = {} } = interactionProject;
 const uid = useSelector((state) => state.firebase.auth.uid);
 const [addQuestion, setAddQuestion] = useState(false);
 const [editQuestion, setEditQuestion] = useState(false);

 const firestore = useFirestore();

 const handlePushToAudience = async (qid) => {
  await firestore.doc(`/users/${uid}/interactions/${id}`).update({
   currentQuestion: qid,
  });
 };

 const handleChangeOrder = async (direction, currentIndex, qid) => {
  const currentOrder = interaction.order || Object.keys(questions).map((question) => question) || []

  let moveToIndex
   if(direction === 'up') {
    moveToIndex = currentIndex - 1
  } else {
    moveToIndex = currentIndex + 1
   }

   const newOrder = Array.from(currentOrder)
   newOrder.splice(currentIndex, 1)
   newOrder.splice(moveToIndex, 0, qid)

    await firestore.doc(`/users/${uid}/interactions/${id}`).update({
     order: newOrder,
   })

 }

 if (checking) {
  return <div className="h-16 bg-gray-900 rounded animate-pulse"></div>;
 }
 return (
  <div className="">
   <div className="flex justify-between">
    <H2RHeading type="h4">Questions</H2RHeading>
    <div className="flex items-center space-x-2">
     <span className="mr-2 text-xs text-gray-500 uppercase">
      Audience: {Object.entries(sessions).length || 0}
     </span>
     <H2RButton
      text="Clear current"
      size="small"
      colour={interaction.currentQuestion === null ? "dark" : "main"}
      icon="double_arrow_right"
      onClick={() => handlePushToAudience(null)}
      disabled={interaction.currentQuestion === null}
     />
    </div>
   </div>
   <div className="mt-2 mb-6 flex flex-col">
    {Object.entries(questions).length === 0 && (
     <span className="italic text-gray-600">No questions added.</span>
    )}
    {Object.entries(questions)
    .sort(([a], [b]) => {
      if(!interaction.order) return null

      return _indexOf(interaction.order, a) - _indexOf(interaction.order, b)
    })
    .map(([qid, question], index) => {
     return (
      <div
       key={qid}
       className="mb-4 px-4 pt-2 pb-4 bg-gray-900 rounded flex flex-col space-y-4"
      >
       <div className="flex justify-between items-center">
        <span className="uppercase text-xs font-bold">
         {questionTypes[question.type]?.name}
        </span>
        <div className="flex gap-2">
          <H2RButton
          text="↑"
          size="small"
          colour="light"
          disabled={index === 0}
          onClick={() => {
            handleChangeOrder('up', index, qid)
          }}
          />
          <H2RButton
          text="↓"
          size="small"
          colour="light"
          disabled={index + 1 === Object.entries(questions).length}
          onClick={() => {
            handleChangeOrder('down', index, qid)
          }}
          />
          <H2RButton
          text=""
          icon="pencil"
          type="link"
          size="small"
          onClick={() => {
            questionActions.editQuestion({ ...question, qid });
            setEditQuestion(true);
          }}
          />
        </div>
       </div>

       <span className="text-3xl font-bold flex justify-between items-center">
        {question.question}
        <span className="text-xs font-semibold text-gray-500">
         {question.totalCount} response(s)
        </span>
       </span>
       {question.type === "poll" && <PollContents question={question} />}
       {question.type === "emoji" && <EmojiContents question={question} />}
       {question.type === "openQandA" && (
        <OpenQandAContents qid={qid} question={question} />
       )}
       {qid !== interaction.currentQuestion ? (
        <H2RButton
         text="Push to audience"
         size="small"
         colour="dark"
         icon="double_arrow_right"
         css="w-100"
         onClick={() => handlePushToAudience(qid)}
         disabled={qid === interaction.currentQuestion}
        />
       ) : (
        <H2RButton
         text="Clear current"
         size="small"
         colour="main"
         icon="double_arrow_right"
         onClick={() => handlePushToAudience(null)}
        />
       )}
      </div>
     );
    })}
   </div>

   <div className="flex space-x-2">
    {/* <H2RButton
     text="Add Poll"
     icon="plus"
     colour="dark"
     size="small"
     onClick={() => {
      handleAddQuestion("poll");
     }}
    />
    <H2RButton
     text="Add Open Q&A"
     icon="plus"
     colour="dark"
     size="small"
     onClick={() => {
      handleAddQuestion("openQandA");
     }}
    /> */}
    <H2RButton
     text="Add Poll"
     size="small"
     colour={"dark"}
     icon="plus"
     onClick={() => {
      questionActions.resetQuestion("poll");
      setAddQuestion(true);
     }}
    />
    <H2RButton
     text="Add Emoji Reaction"
     size="small"
     colour={"dark"}
     icon="plus"
     onClick={() => {
      questionActions.resetQuestion("emoji");
      setAddQuestion(true);
     }}
    />
    <H2RButton
     text="Add Open Q&A"
     size="small"
     colour={"dark"}
     icon="plus"
     onClick={() => {
      questionActions.resetQuestion("openQandA");
      setAddQuestion(true);
     }}
    />
   </div>
   <AddQuestionSlideover
    open={addQuestion}
    onClose={() => {
     questionActions.resetQuestion("poll");
     setAddQuestion(false);
    }}
    id={id}
   />
   <EditQuestionSlideover
    open={editQuestion}
    onClose={() => {
     questionActions.resetQuestion("poll");
     setEditQuestion(false);
    }}
    id={id}
   />
  </div>
 );
};

const PollContents = ({ question }) => {
 return (
  <div className="flex flex-col space-y-2">
   {question.options.map((option, index) => {
    return (
     <div
      key={index}
      className="bg-gray-800 relative rounded flex w-full items-center"
     >
      <span
       style={{
        width: calculatePercentage(
         question.results?.[index],
         question.totalCount
        ),
        // width: `${Math.floor(
        //  (question.results?.[index] / question.totalCount) * 100
        // )}%`,
       }}
       className="absolute rounded h-full w-100 bg-gray-700 z-0 transition-all duration-300"
      ></span>
      <span className="px-2 z-10 text-sm font-semibold text-gray-600">
       {index + 1}
      </span>{" "}
      <div className="flex justify-between w-full">
       <span className="z-10">{option.text}</span>{" "}
       <span className="mr-2 z-10 text-gray-500">
        {question.results?.[index] > 0 &&
         `${calculatePercentage(
          question.results?.[index],
          question.totalCount
         )}`}
       </span>
      </div>
     </div>
    );
   })}
  </div>
 );
};
const EmojiContents = ({ question }) => {
 return (
  <div className="flex w-100 items-center justify-center space-x-2">
   {question.options.map((option, index) => {
    return (
     <div
      key={index}
      className="bg-gray-800 h-20 w-20 rounded flex justify-center items-center text-4xl"
     >
      <div className="flex flex-col w-100 justify-between items-center space-y-2">
       <span className="mt-2">{option.text}</span>
       <span className="text-gray-500 text-xs">
        {question.results?.[index] > 0 && question.results?.[index]}
       </span>
      </div>
     </div>
    );
   })}
  </div>
 );
};

const OpenQandAContents = ({ qid, question }) => {
 const { id } = useParams();
 const [expanded, setExpanded] = useState(false);
 const uid = useSelector((state) => state.firebase.auth.uid);

 const firestore = useFirestore();

 const handleSetShowOnOutputIndex = async (index) => {
  await firestore
   .doc(`/users/${uid}/interactions/${id}/questions/${qid}`)
   .update({
    showOnOutputIndex: index,
   });
 };

 return (
  <div className="flex flex-col space-y-2">
   {question?.results?.length > 0 ? (
    <>
     {question?.results?.map((answer, index) => {
      if (!expanded && index > 4) return null;
      return (
       <div
        key={index}
        className="bg-gray-800 hover:bg-gray-700 rounded flex w-full items-center"
       >
        <div className="px-2 py-1">
         <span>{answer.text}</span>
         <span className="mr-2 text-gray-500">
          {" "}
          • {answer.name || "Anonymous"}
         </span>
        </div>
        <button
         onClick={() => {
          if (question.showOnOutputIndex === index) {
           handleSetShowOnOutputIndex(null);
          } else {
           handleSetShowOnOutputIndex(index);
          }
         }}
         className={`ml-auto py-1 text-sm px-1 rounded focus:outline-none focus:ring
         ${
          question.showOnOutputIndex === index
           ? "bg-main-500 text-white hover:bg-main-400"
           : "bg-gray-700 text-gray-400 hover:bg-gray-600"
         }
         `}
        >
         {question.showOnOutputIndex === index ? "Hide" : "Show"}
        </button>
       </div>
      );
     })}
     {question?.results?.length > 5 && (
      <button
       className="text-gray-400"
       onClick={() => {
        setExpanded(!expanded);
       }}
      >
       {expanded ? "↑ Collapse ↑" : "↓ Show more responses ↓"}
      </button>
     )}
    </>
   ) : (
    <span className="text-sm text-gray-500">No responses</span>
   )}
  </div>
 );
};

const useGetInteractionProjects = (id) => {
 const [checking, setChecking] = useState(true);
 const [data, setData] = useState({});
 const [questions, setQuestions] = useState({});
 const [sessions, setSessions] = useState({});
 const [empty, setEmpty] = useState(false);

 const uid = useSelector((state) => state.firebase.auth.uid);

 const firestore = useFirestore();

 const fetchData = async () => {
  setChecking(true);
  await firestore.onSnapshot(
   {
    collection: `users/${uid}/interactions`,
    doc: id,
    storeAs: `interaction_${id}`,
   },
   (res) => {
    if (!res.exists) {
     setChecking(false);
     return setEmpty(true);
    }

    setData(res.data());
   }
  );

  await firestore.onSnapshot(
   {
    collection: `users/${uid}/interactions/${id}/sessions`,
    storeAs: `interaction_${id}_sessions`,
   },
   (res) => {
    if (res.docs.length === 0) {
     return null;
    }

    let docs = {};

    res.docs.map((d) => {
     return (docs = {
      ...docs,
      [d.id]: {
       ...d.data(),
      },
     });
    });

    return setSessions(docs);
   }
  );

  await firestore.onSnapshot(
   {
    collection: `users/${uid}/interactions/${id}/questions`,
    orderBy: ["added", "asc"],
    storeAs: `interaction_${id}_questions`,
   },
   (res) => {
    if (res.docs.length === 0) {
     setChecking(false);
    }

    let docs = {};

    res.docs.map((d) => {
     return (docs = {
      ...docs,
      [d.id]: {
       ...d.data(),
      },
     });
    });

    setChecking(false);
    return setQuestions(docs);
   }
  );
 };

 useEffect(() => {
  fetchData();

  return () => {
   firestore.unsetListener(`users/${uid}/interactions/${id}`);
   firestore.unsetListener(`users/${uid}/interactions/${id}/questions`);
   firestore.unsetListener(`users/${uid}/interactions/${id}/sessions`);
  };
 }, []);

 if (checking)
  return {
   status: "CHECKING",
   data: { interaction: data, questions, sessions },
  };

 if (empty)
  return { status: "EMPTY", data: { interaction: data, questions, sessions } };

 return { status: "DONE", data: { interaction: data, questions, sessions } };
};

const AddQuestionSlideover = ({ open, onClose, id }) => {
 const [adding, setAdding] = useState(false);

 const firestore = useFirestore();

 const uid = useSelector((state) => state.firebase.auth.uid);
 const question = useSelector((state) => state.question);

 const handleAddQuestion = async () => {
  setAdding(true);
  let data = {
   added: new Date(),
   ...question,
  };

  // if (interaction.type === "poll") {
  //  data = {
  //   ...data,
  //   options: [...interaction.options],
  //  };
  // }

  await firestore.collection(`/users/${uid}/interactions/${id}/questions`).add({
   ...data,
  });

  // setOptions([...questionTypes["poll"].options]);
  questionActions.resetQuestion("poll");
  setAdding(false);
  onClose();
 };

 return (
  <Slideover open={open} onClose={onClose} title="Add question">
   <div className="flex-grow">
    {/* <H2RSelect
     label="Type"
     colour="dark"
     disabled={true}
     selected={question.type}
     onChange={(change) => {
      return questionActions.resetQuestion(change);
     }}
     options={[
      ...Object.entries(questionTypes).map(([t, type]) => {
       return {
        label: type.name,
        value: type.type,
       };
      }),
     ]}
    /> */}
    <H2RInput
     label="Question"
     colour="light"
     value={question.question}
     onChange={(text) => questionActions.updateQuestion({ question: text })}
    />
    {(question.type === "poll" || question.type === "emoji") && (
     <div className="pt-2 flex flex-col space-y-1 justify-center">
      <span className="text-gray-500 font-semibold text-sm">Options</span>
      {question.options.map((op, i) => {
       return (
        <div key={op.id} className="flex space-x-2">
         <span className="w-3">{i + 1}</span>
         <H2RInput
          value={op.text}
          size="large"
          colour="light"
          onChange={(text) => {
           let current = Array.from(question.options);
           current[i] = { text, id: op.id };

           questionActions.updateQuestion({ options: current });
          }}
         />
         <H2RButton
          text=""
          colour="dark"
          icon="x"
          size="small"
          type="link"
          onClick={() => {
           let current = Array.from(question.options);
           current.splice(i, 1);

           questionActions.updateQuestion({ options: [...current] });
          }}
         />
        </div>
       );
      })}
      <H2RButton
       text="Add option"
       icon="plus"
       size="small"
       colour="light"
       onClick={() => {
        let id = question.options?.[question.options.length - 1]?.id + 1 || 1;
        questionActions.updateQuestion({
         options: [...question.options, { id, text: `Option ${id}` }],
        });
       }}
      />
     </div>
    )}
    {question.type === "openQandA" && (
     <div className="pt-2 flex flex-col space-y-1 justify-center">
      {/* <span className="text-gray-500 font-semibold text-sm">Options</span> */}
      <H2RCheckbox
       label="Allow multple responses?"
       checkboxLabel={
        question.allowMultiple
         ? "Audience members can answer multiple times"
         : "Audience members can only answer once"
       }
       checked={question.allowMultiple}
       onChange={(change) =>
        questionActions.updateQuestion({ allowMultiple: change })
       }
      />
     </div>
    )}
   </div>
   <H2RButton
    text="Add question"
    icon="plus"
    size="small"
    disabled={adding}
    onClick={() => {
     handleAddQuestion();
    }}
   />
  </Slideover>
 );
};

const EditQuestionSlideover = ({ open, onClose, id }) => {
 const [updating, setUpdating] = useState(false);

 const firestore = useFirestore();

 const uid = useSelector((state) => state.firebase.auth.uid);
 const question = useSelector((state) => state.question);

 const handleUpdateQuestion = async () => {
  setUpdating(true);
  let data = {
   updated: new Date(),
   ...question,
  };

  await firestore
   .collection(`/users/${uid}/interactions/${id}/questions`)
   .doc(question.qid)
   .update(
    {
     ...data,
    },
    { merge: true }
   );

  questionActions.resetQuestion("poll");
  setUpdating(false);
  onClose();
 };

 const handleDuplicateQuestion = async (reset = false) => {
  setUpdating(true);
  let data = {
   ...question,
   question: `${question.question} (copy)`,
   updated: new Date(),
   results: null,
   totalCount: 0,
   qid: null,
   added: new Date(),
  };

  if (reset === true) {
   data = {
    ...data,
    added: question.added,
    question: `${question.question}`,
   };
  }

  await firestore.collection(`/users/${uid}/interactions/${id}/questions`).add({
   ...data,
  });

  if (reset === true) {
   return handleRemoveQuestion();
  }

  questionActions.resetQuestion("poll");
  setUpdating(false);
  onClose();
 };

 const handleRemoveQuestion = async () => {
  setUpdating(true);

  await firestore
   .doc(`/users/${uid}/interactions/${id}/questions/${question.qid}`)
   .delete();

  setUpdating(false);
  onClose();
 };

 return (
  <Slideover open={open} onClose={onClose} title="Edit question">
   <div className="flex-grow">
    <H2RInput
     label="Type"
     value={question.name}
     disabled={true}
     onChange={() => null}
    />
    <H2RInput
     label="Question"
     colour="light"
     value={question.question}
     onChange={(text) => questionActions.updateQuestion({ question: text })}
    />
    {(question.type === "poll" || question.type === "emoji") && (
     <div className="pt-2 flex flex-col space-y-1 justify-center">
      <span className="text-gray-500 font-semibold text-sm">Options</span>
      {question.options.map((op, i) => {
       return (
        <div key={op.id} className="flex space-x-2">
         <span className="w-3">{i + 1}</span>
         <H2RInput
          value={op.text}
          size="large"
          colour="light"
          onChange={(text) => {
           let current = Array.from(question.options);
           current[i] = { text, id: op.id };

           questionActions.updateQuestion({ options: current });
          }}
         />
         <H2RButton
          colour="dark"
          icon="x"
          size="small"
          type="link"
          text=""
          disabled={question.totalCount > 0}
          onClick={() => {
           let current = Array.from(question.options);
           current.splice(i, 1);

           questionActions.updateQuestion({ options: [...current] });
          }}
         />
        </div>
       );
      })}
      <H2RButton
       text="Add option"
       icon="plus"
       size="small"
       colour="light"
       onClick={() => {
        let id = question.options?.[question.options.length - 1]?.id + 1 || 1;
        questionActions.updateQuestion({
         options: [...question.options, { id, text: `Option ${id}` }],
        });
       }}
      />
     </div>
    )}
    {question.type === "openQandA" && (
     <div className="pt-2 flex flex-col space-y-1 justify-center">
      {/* <span className="text-gray-500 font-semibold text-sm">Options</span> */}
      <H2RCheckbox
       label="Allow multple responses?"
       checkboxLabel={
        question.allowMultiple
         ? "Audience members can answer multiple times"
         : "Audience members can only answer once"
       }
       checked={question.allowMultiple}
       onChange={(change) =>
        questionActions.updateQuestion({ allowMultiple: change })
       }
      />
     </div>
    )}
    <span className="my-6 h-1 w-full block border-t border-gray-700"></span>
    <div className="flex flex-col space-y-4">
     <H2RHeading type="h6">Actions</H2RHeading>
     <H2RButton
      icon="copy"
      text="Duplicate question"
      size="small"
      colour="dark"
      onClick={handleDuplicateQuestion}
     />
     <H2RButton
      icon="refresh"
      text="Reset results"
      size="small"
      colour="dark"
      onClick={() => {
       handleDuplicateQuestion(true);
      }}
     />
     <H2RButton
      icon="x"
      text="Delete question"
      size="small"
      colour="dark"
      onClick={handleRemoveQuestion}
     />
    </div>
   </div>
   <H2RButton
    text="Save question"
    size="small"
    disabled={updating}
    onClick={() => {
     handleUpdateQuestion();
    }}
   />
  </Slideover>
 );
};
