import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import moment from "moment";
import randomatic from "randomatic";

import Page from "./Entry";
import H2RHeading from "./H2RComponents/H2RHeading";
import H2RButton from "./H2RComponents/H2RButton";
import H2RInput from "./H2RComponents/H2RInput";
import { Link, useHistory } from "react-router-dom";
import Slideover from "./Slideover";
import Collapsable from "./snippets/Collapsable";
import InputWithCopy from "./snippets/InputWithCopy";
import idToCode from "../helpers/idToCode";

export default function Interactions() {
 const [showCredentials, setShowCredentials] = useState(false);

 // NEW INTERACTION
 const [newName, setNewName] = useState("");
 const [adding, setAdding] = useState(false);

 const [showAddInteractionModal, setShowAddInteractionModal] = useState(false);

 const { status } = useGetInteractionProjects();
 const data = useSelector((state) => state.firestore.ordered.interactions);

 const uid = useSelector((state) => state.firebase.auth.uid);
 const user = useSelector((state) => state.firebase.profile);

 const firestore = useFirestore();
 const history = useHistory();

 const handleCreateProject = async () => {
  setAdding(true);
  let randId = randomatic("0", 6);
  //   let randId = 33486553;

  const idChecked = await firestore
   .doc(`codes/${randId}`)
   .get()
   .then((doc) => {
    if (doc.exists) {
     let expires = doc.data().expires.seconds * 1000;
     if (expires - new Date().getTime() < 0) {
      return randId;
     } else {
      return randId + 1;
     }
    } else {
     return randId;
    }
   });

  let date = new Date();
  date.setDate(date.getDate() + 7);

  await firestore.doc(`codes/${idChecked}`).set({
   expires: date,
   uid,
  });

  await firestore.doc(`users/${uid}/interactions/${idChecked}`).set({
   code: idChecked,
   lastUpdate: new Date(),
   name: newName || "New interaction project",
   currentQuestion: null,
   expires: date,
  });

  history.push(`/interactions/${idChecked}`);
 };

 return (
  <Page title="Interactions" subtitle="Interact with your audience.">
   {/* <Collapsable
    heading="Credentials"
    opened={showCredentials}
    toggle={() => {
     setShowCredentials(!showCredentials);
    }}
   >
    <div className="flex flex-col space-y-4">
     <InputWithCopy value={uid} label={`User ID`} />

     <UserToken type="Read" />
    </div>
   </Collapsable> */}
   <div className="flex justify-between">
    <H2RHeading type="h4">Recent interactions</H2RHeading>
    <H2RButton
     text="Create"
     icon="plus"
     size="small"
     colour="dark"
     disabled={!user?.features?.interactions}
     onClick={() => setShowAddInteractionModal(true)}
    />
   </div>
   <Slideover
    open={showAddInteractionModal}
    onClose={() => setShowAddInteractionModal(false)}
    title={`Create new interaction`}
   >
    <H2RInput
     label="Name"
     colour="light"
     placeholder="My interaction project"
     value={newName}
     onChange={(text) => setNewName(text)}
    />
    <H2RInput
     label="Code expires 7 days from now"
     value={moment().add(7, "days").format("Do MMMM YYYY")}
     disabled={true}
     onChange={() => null}
    />
    <H2RButton
     text="Create"
     disabled={adding}
     size="small"
     onClick={handleCreateProject}
    />
   </Slideover>
   <div className="mt-4 w-full flex flex-col space-y-4">
    {!user?.features?.interactions && (
     <span className="px-4 py-4 border border-red-700  rounded">
      Interactions is an invite-only feature for now.{" "}
      <a className="underline" href="mailto:support@heretorecord.com">
       Please get in touch to get an invite
      </a>
      .
     </span>
    )}
    {status === "CHECKING" && <InteractionProjects checking={true} />}
    {status === "EMPTY" && (
     <span className="italic text-gray-600">No interactions found.</span>
    )}
    {status === "DONE" && (
     <>
      {data.map((di, i) => {
       return (
        <InteractionProjects key={i} id={di.id} interactionProject={di} />
       );
      })}
     </>
    )}
   </div>
  </Page>
 );
}

const InteractionProjects = ({ checking = false, id, interactionProject }) => {
 if (checking) {
  return <div className="h-16 bg-gray-900 rounded animate-pulse"></div>;
 }
 return (
  <div className="px-6 py-4 w-full flex justify-between items-center bg-gray-900 rounded">
   <div className="flex flex-col">
    <span className="font-bold">{interactionProject.name || "No name"}</span>
    <span className="text-xs text-gray-500">Code: {idToCode(id)}</span>
   </div>

   <div>
    <span className="mr-2 text-sm italic text-gray-600">
     Created:{" "}
     {moment(interactionProject.lastUpdate?.toDate()).format(
      "Do MMM 'YY, HH:mm:ss"
     )}
    </span>
    <Link
     className="px-4 py-3 rounded hover:bg-gray-800 focus:outline-none focus:ring ring-main-500 transition-all"
     to={`/interactions/${id}`}
    >
     →
    </Link>
   </div>
  </div>
 );
};

const useGetInteractionProjects = () => {
 let [checking, setChecking] = useState(true);
 let [data, setData] = useState({});
 const [empty, setEmpty] = useState(false);

 const uid = useSelector((state) => state.firebase.auth.uid);

 const firestore = useFirestore();

 const fetchData = async () => {
  setChecking(true);
  firestore.onSnapshot(
   {
    collection: `users/${uid}/interactions`,
    storeAs: "interactions",
    orderBy: ["lastUpdate", "desc"],
   },
   (res) => {
    if (res.docs.length === 0) {
     setChecking(false);
     return setEmpty(true);
    }

    let docs = {};

    res.docs.map((d) => {
     return (docs = {
      ...docs,
      [d.id]: {
       ...d.data(),
      },
     });
    });

    setData(docs);
    setChecking(false);
   }
  );
 };

 useEffect(() => {
  fetchData();

  return () => {
   firestore.unsetListener(`users/${uid}/interactions`);
  };
 }, []);

 if (checking) return { status: "CHECKING", data: data };

 if (empty) return { status: "EMPTY", data: data };

 return { status: "DONE", data: data };
};

const UserToken = ({ type = "Read" }) => {
 const uid = useSelector((state) => state.firebase.auth.uid);

 const tokenRead =
  useSelector((state) => state.firebase.profile.interactionsTokenReadyOnly) ||
  "No token generated";

 const firestore = useFirestore();

 const handleGenerateNewToken = async (t) => {
  if (t === "Read") {
   await firestore.doc(`users/${uid}`).update({
    interactionsTokenReadyOnly: randomatic("Aa0", 30),
   });
  }
 };

 return (
  <div className="flex flex-col  ">
   <InputWithCopy
    label={`Token - ${type}`}
    value={type === "Read" && tokenRead}
    // type="password"
   />
   {/* <span className="mt-1 uppercase text-xs text-gray-600 font-semibold">
    Token - {type}
   </span>
   <span className="px-1 mt-1 text-sm bg-gray-700 rounded text-gray-400 select-text overflow-hidden">
    {type === "Read" && tokenRead}
   </span> */}
   <button
    className="mt-1 w-min whitespace-nowrap text-gray-500 text-xs font-semibold uppercase ml-auto focus:outline-none focus:ring rounded"
    onClick={() => handleGenerateNewToken(type)}
   >
    Generate new token
   </button>
  </div>
 );
};
