import { useFirebase } from "react-redux-firebase";
import { Link, NavLink } from "react-router-dom";

import H2RHeading from "./H2RComponents/H2RHeading";
import Logo from "./Logo";

export default function Page({
 title,
 subtitle,
 nav = true,
 breadcrumb = "",
 children,
}) {
 return (
  <div className="h-auto min-h-screen w-screen bg-gray-800 flex flex-col sm:flex-row">
   {nav && <Sidebar />}
   <Content>
    {breadcrumb !== "" && (
     <Link
      to={`${breadcrumb}`}
      className={`mb-2 px-3 py-2 opacity-50 hover:opacity-100 hover:bg-gray-900 rounded transition-colors`}
     >
      ← Back
     </Link>
    )}
    <H2RHeading type="h2">{title}</H2RHeading>
    <span className="text-gray-500">{subtitle}</span>
    <div className="mt-4 ">{children}</div>
   </Content>
  </div>
 );
}

const links = [
 //  {
 //   id: 0,
 //   label: "Projects",
 //   to: "/projects",
 //  },
 {
  id: 1,
  label: "Sync (beta)",
  to: "/sync",
  exact: false,
 },
 // {
 // 	id: 1,
 // 	label: "Team",
 // 	to: "/team",
 //  },
 {
  id: 2,
  label: "Interactions (beta)",
  to: "/interactions",
  exact: false,
 },
 {
  id: 3,
  label: "Settings",
  to: "/",
  exact: true,
 },
];

const Sidebar = () => {
 const firebase = useFirebase();

 const handleLogout = async () => {
  await firebase.logout();
 };
 return (
  <nav className="md:h-screen bg-gray-900 w-auto sm:w-80">
   <Logo />
   <div className="w-full flex justify-between sm:flex-col">
    {links.map((link) => {
     return (
      <NavLink
       className={`px-4 py-4 text-center sm:text-left w-full hover:bg-gray-800 transition-all duration-200 border-0 border-gray-900`}
       activeClassName={`bg-gray-800 border-b-4 sm:border-l-4 sm:border-b-0 ring-l border-gray-900 font-semibold`}
       key={link.id}
       to={link.to}
       exact={link.exact}
      >
       {link.label}
      </NavLink>
     );
    })}
   </div>
   <span className="mt-10 h-1 w-full inline-block bg-gray-800"></span>
   <button
    className={`px-4 py-4 text-center sm:text-left w-full hover:bg-gray-800 transition-all duration-200 border-0 border-gray-900`}
    onClick={handleLogout}
   >
    Logout
   </button>
  </nav>
 );
};

const Content = ({ children }) => {
 return (
  <main className="px-4 lg:px-10 py-10 w-full overflow-auto h-screen">
   {children}
  </main>
 );
};
