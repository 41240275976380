import H2RHeading from "../H2RComponents/H2RHeading";

export default function Page({ heading = "", children }) {
 return (
  <div className="bg-gray-800 h-screen">
   <main
    // style={{ height: `calc(100vh - 100px)` }}
    className="w-screen bg-gray-800"
   >
    <div className="px-4 pt-4 mx-auto max-w-xl flex flex-col h-full space-y-3">
     <H2RHeading type="h2">{heading}</H2RHeading>
     {children}
     {/* <section className="py-4 text-xs text-gray-500">
      Powered by H2R Graphics Cloud
     </section> */}
    </div>
   </main>
  </div>
 );
}
