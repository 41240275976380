import React from "react";

export default function H2RHeading({ type = "h1", children }) {
	switch (type) {
		case "h1":
			return (
				<h1 className="text-5xl font-black pb-4 text-gray-100">{children}</h1>
			);
		case "h2":
			return (
				<h2 className="text-4xl font-bold pb-3 text-gray-200">{children}</h2>
			);

		case "h3":
			return (
				<h3 className="text-3xl font-semibold pb-2 text-gray-300">
					{children}
				</h3>
			);

		case "h4":
			return (
				<h4 className="text-2xl font-medium pb-1 text-gray-300">{children}</h4>
			);

		case "h5":
			return <h5 className=" text-xl font-base text-gray-300">{children}</h5>;

		case "h6":
			return <h6 className="text-lg font-base text-gray-400">{children}</h6>;

		default:
			return children;
	}
}
