import H2RHeading from "../H2RComponents/H2RHeading";

export default function Collapsable({ heading, opened, toggle, children }) {
 return (
  <div className="mb-4 border-2 border-dashed border-gray-900 rounded">
   <button
    onClick={toggle}
    className="px-4 py-4 w-full flex justify-between text-gray-400 rounded focus:outline-none focus:text-gray-200"
   >
    <H2RHeading type="h5">{heading}</H2RHeading>
    <span className="">{opened ? "↓" : "←"}</span>
   </button>
   {opened && <div className="px-4 pb-4">{children}</div>}
  </div>
 );
}
