import logo from "../img/logo.png";

export default function Logo() {
	return (
		<div className="px-6 py-10 flex space-x-3 justify-center items-center">
			<img src={logo} alt="Graphics logo" className="w-20 h-20" />
			<span className="font-bold text-xl w-min leading-6">
				H2R Graphics{" "}
				<span className="text-sm uppercase text-gray-400 bg-gray-800 px-1 py-0.5 rounded">
					Cloud
				</span>
			</span>
		</div>
	);
}
